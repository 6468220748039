module services {
    export module tariff {
        export class tariffBookNotesService implements interfaces.tariffbooknotes.ITariffBookNotesService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            saveTariffBookNote(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffBookNotes/SaveTariffBookNote", {
                });
            }

            getTariffBookNotes(): ng.resource.IResourceClass<interfaces.tariffbooknotes.ITariffBookNotesDisplay> {
                return this.$resource<interfaces.tariffbooknotes.ITariffBookNotesDisplay>(this.ENV.DSP_URL + "TariffBookNotes/GetTariffBookNotes", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    });
            }

            getTariffBookNote(): ng.resource.IResourceClass<interfaces.tariffbooknotes.ITariffBookNote> {
                return this.$resource<interfaces.tariffbooknotes.ITariffBookNote>(this.ENV.DSP_URL + "TariffBookNotes/GetTariffBookNote", {
                    tnnId: '@tnnId',
                    copyNote: '@copyNote'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

        }
    }

    angular.module("app").service("tariffBookNotesService", services.tariff.tariffBookNotesService);
}